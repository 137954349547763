<!--
  ~ /*
  ~  * Copyright 2016-2017 the original author or authors.
  ~  *
  ~  * Licensed under the Apache License, Version 2.0 (the "License");
  ~  * you may not use this file except in compliance with the License.
  ~  * You may obtain a copy of the License at
  ~  *
  ~  *      http://www.apache.org/licenses/LICENSE-2.0
  ~  *
  ~  * Unless required by applicable law or agreed to in writing, software
  ~  * distributed under the License is distributed on an "AS IS" BASIS,
  ~  * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  ~  * See the License for the specific language governing permissions and
  ~  * limitations under the License.
  ~  */
  -->

<div class="container-fluid">
  <div class="container xd-container">
    <h2>Owners</h2>

    <form method="get"
          class="form-horizontal" id="search-owner-form">
      <div class="form-group">
        <div class="control-group" id="lastNameGroup">
          <label class="col-sm-2 control-label">Last name </label>
          <div class="col-sm-10">
            <input class="form-control" size="30"
                   maxlength="80" id="lastName" name="lastName" [(ngModel)]="lastName" value=""/> <span class="help-inline"></span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-offset-2 col-sm-10">
          <button type="submit" class="btn btn-default" (click)="searchByLastName(lastName)">Find
            Owner</button>
        </div>
      </div>

    </form>

    <div *ngIf="!owners">No owners with LastName starting with "{{lastName}}"</div>
    <div class="table-responsive" id="ownersTable"  *ngIf="owners">
      <table class="table table-striped">
        <thead>
        <tr>
          <th>Name</th>
          <th>Address</th>
          <th>City</th>
          <th>Telephone</th>
          <th>Pets</th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let owner of owners">
          <td class="ownerFullName"><a routerLink="/owners/{{owner.id}}" routerLinkActive="active"
                                       (click)="onSelect(owner)">{{ owner.firstName }} {{ owner.lastName }}</a></td>
          <td>{{ owner.address }}</td>
          <td>{{ owner.city }}</td>
          <td>{{ owner.telephone }}</td>
          <td>
        <tr *ngFor="let pet of owner.pets">
          {{ pet.name }}
        </tr>
        </td>
        </tr>
        </tbody>
      </table>
      <div>
        <button *ngIf="isOwnersDataReceived" class="btn btn-default" (click)="addOwner()">Add Owner</button>
      </div>
    </div>
  </div>
</div>
