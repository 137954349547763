<!--
  ~ /*
  ~  * Copyright 2016-2017 the original author or authors.
  ~  *
  ~  * Licensed under the Apache License, Version 2.0 (the "License");
  ~  * you may not use this file except in compliance with the License.
  ~  * You may obtain a copy of the License at
  ~  *
  ~  *      http://www.apache.org/licenses/LICENSE-2.0
  ~  *
  ~  * Unless required by applicable law or agreed to in writing, software
  ~  * distributed under the License is distributed on an "AS IS" BASIS,
  ~  * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  ~  * See the License for the specific language governing permissions and
  ~  * limitations under the License.
  ~  */
  -->

<h1 class="title">Welcome to Petclinic</h1>

<div class="container-fluid">
  <div class="container xd-container">

    <h2>Welcome</h2>
    <div class="row">
      <div class="col-md-12">
        <img class="img-responsive" src="./assets/images/pets.png" alt="pets logo"/>
      </div>
    </div>
  </div>
